import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "container float-start ms-8" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "" }
const _hoisted_5 = { class: "text-reader" }
const _hoisted_6 = { class: "container" }
const _hoisted_7 = { class: "mt-2" }
const _hoisted_8 = ["data-kt-indicator"]
const _hoisted_9 = { class: "row" }
const _hoisted_10 = {
  key: 0,
  class: "col-md-10"
}
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = {
  class: "modal fade",
  tabindex: "-1",
  id: "kt_modal_1"
}
const _hoisted_14 = { class: "modal-dialog modal-dialog-centered modal-xl" }
const _hoisted_15 = { class: "modal-content" }
const _hoisted_16 = { class: "row d-flex justify-content-end" }
const _hoisted_17 = { class: "col-6 m-5" }
const _hoisted_18 = { class: "modal-body" }
const _hoisted_19 = { class: "card-body pt-0" }
const _hoisted_20 = { class: "card-body tableFixHead table-responsive" }
const _hoisted_21 = { class: "table table-striped table-active table-hover table-bordered text-center p-5" }
const _hoisted_22 = { class: "fs-4 fw-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createCommentVNode("3rd row"),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("label", _hoisted_5, [
              _createElementVNode("input", {
                type: "file",
                class: "form-control",
                accept: ".csv",
                onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleFileUpload($event)))
              }, null, 32 /* NEED_HYDRATION */)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "mt-3" }, [
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-success w-150px",
            "data-bs-toggle": "modal",
            "data-bs-target": "#kt_modal_1",
            id: "kt_toolbar_primary_button"
          }, " Preview ")
        ], -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("button", {
            type: "submit",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args))),
            class: "btn btn-danger w-150px",
            "data-kt-indicator": _ctx.loading
          }, _cache[4] || (_cache[4] = [
            _createElementVNode("span", { class: "indicator-label" }, " Decode Logs ", -1 /* HOISTED */),
            _createElementVNode("span", { class: "indicator-progress" }, [
              _createTextVNode(" Please wait... "),
              _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" })
            ], -1 /* HOISTED */)
          ]), 8 /* PROPS */, _hoisted_8)
        ])
      ]),
      _createCommentVNode("4th row"),
      _createCommentVNode("5th row"),
      _createElementVNode("div", _hoisted_9, [
        _cache[10] || (_cache[10] = _createElementVNode("div", { class: "col-md-2" }, null, -1 /* HOISTED */)),
        (_ctx.respCSV.success)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("b", null, "Added IDs (" + _toDisplayString(_ctx.respCSV.added.length) + "):", 1 /* TEXT */),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.respCSV.added, (device, index) => {
                return (_openBlock(), _createElementBlock("span", { key: index }, _toDisplayString(device.deviceId) + ", ", 1 /* TEXT */))
              }), 128 /* KEYED_FRAGMENT */)),
              _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
              _createElementVNode("b", null, "Skipped IDs (" + _toDisplayString(_ctx.respCSV.skipped.length) + "):", 1 /* TEXT */),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.respCSV.skipped, (device, index) => {
                return (_openBlock(), _createElementBlock("span", { key: index }, _toDisplayString(device.deviceId) + ", ", 1 /* TEXT */))
              }), 128 /* KEYED_FRAGMENT */)),
              _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
              _createElementVNode("b", null, "Error messages (" + _toDisplayString(_ctx.respCSV.errors.length) + "):", 1 /* TEXT */),
              _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.respCSV.errors, (device, index) => {
                return (_openBlock(), _createElementBlock("span", { key: index }, [
                  _createTextVNode(" Device Id " + _toDisplayString(device.deviceId) + ": ", 1 /* TEXT */),
                  (_ctx.isObject(device.message))
                    ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(device.message, (item, index) => {
                          return (_openBlock(), _createElementBlock("div", { key: index }, _toDisplayString(item[0]), 1 /* TEXT */))
                        }), 128 /* KEYED_FRAGMENT */))
                      ]))
                    : _createCommentVNode("v-if", true),
                  (!_ctx.isObject(device.message))
                    ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(device.message), 1 /* TEXT */))
                    : _createCommentVNode("v-if", true),
                  _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                  _createCommentVNode(" </span>  ")
                ]))
              }), 128 /* KEYED_FRAGMENT */))
            ]))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    _createCommentVNode(" new thing "),
    _createCommentVNode(" \r\n  <div class=\"card-title d-flex justify-content-start \">\r\n    <label>Please a select variant:</label>\r\n    <select\r\n      name=\"status\"\r\n      data-control=\"select2\"\r\n      data-hide-search=\"true\"\r\n      v-model=\"variant\"\r\n      class=\"form-select form-select-white form-select-sm m-l-12 w-300px\"\r\n    >\r\n      <option value=\"null\" disabled selected hidden>Variant *</option>\r\n      <option value=\"sigfox\">Sigfox</option>\r\n      <option value=\"lora\">Lora</option>\r\n      <option value=\"nbiot\">NB IoT</option>\r\n    </select>\r\n  </div>"),
    _createCommentVNode("begin::Row"),
    _createCommentVNode(" <div class=\"row g-6 mb-6 g-xl-9 mb-xl-9\">np\r\n    begin::Followers"),
    _createCommentVNode(" <label class=\"text-reader\">\r\n      <input\r\n        type=\"file\"\r\n        class=\"btn btn-primary\"\r\n        accept=\".csv\"\r\n        @change=\"handleFileUpload($event)\"\r\n      />\r\n    </label>\r\n    <div class=\"col-md-5\">\r\n      <button\r\n        type=\"button\"\r\n        style=\"float: right; margin-left: 3px\"\r\n        class=\"btn btn-primary\"\r\n        data-bs-toggle=\"modal\"\r\n        data-bs-target=\"#kt_modal_1\"\r\n        id=\"kt_toolbar_primary_button\"\r\n      >\r\n        Preview\r\n      </button>\r\n      <button\r\n        style=\"float: right\"\r\n        type=\"submit\"\r\n        @click=\"submit\"\r\n        class=\"btn btn-primary\"\r\n        :data-kt-indicator=\"loading\"\r\n      >\r\n        <span class=\"indicator-label\"> Upload </span>\r\n        <span class=\"indicator-progress\">\r\n          Please wait...\r\n          <span\r\n            class=\"spinner-border spinner-border-sm align-middle ms-2\"\r\n          ></span>\r\n        </span>\r\n      </button>\r\n\r\n      <a target=\"_blank\" download href=\"/sample_files/Lora.csv\">\r\n        Sample CSV Lora\r\n      </a>\r\n      <br />\r\n      <a target=\"_blank\" download href=\"/sample_files/Sigfox.csv\">\r\n        Sample CSV Sigfox\r\n      </a>\r\n      <br />\r\n      <a target=\"_blank\" download href=\"/sample_files/NBIoTEm-Com.csv\">\r\n        Sample CSV NBIOT\r\n      </a>\r\n    </div>\r\n\r\n    \r\n    end::Followers"),
    _createCommentVNode("</div>"),
    _createCommentVNode("end::Row"),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, [
          _cache[11] || (_cache[11] = _createElementVNode("div", { class: "modal-header bg-primary" }, [
            _createElementVNode("span", { class: "modal-title h5 text-white text-center" }, "CSV Preview"),
            _createCommentVNode("begin::Close"),
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-icon btn-sm",
              style: {"border-radius":"50px"},
              "data-bs-dismiss": "modal",
              "aria-label": "Close",
              id: "addnewnotificationmodal"
            }, [
              _createElementVNode("i", {
                class: "bi bi-x-circle-fill light-text fs-3",
                style: {"color":"white"}
              })
            ]),
            _createCommentVNode("end::Close")
          ], -1 /* HOISTED */)),
          _createCommentVNode(" input start "),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.searchData(_ctx.searchValue))),
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.searchValue) = $event)),
                class: "form-control",
                placeholder: "Search Device"
              }, null, 544 /* NEED_HYDRATION, NEED_PATCH */), [
                [_vModelText, _ctx.searchValue]
              ])
            ])
          ]),
          _createCommentVNode(" input end "),
          _createCommentVNode(" Pagination start  "),
          _createElementVNode("div", _hoisted_18, [
            _createCommentVNode(" KTDataTable start "),
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("div", _hoisted_20, [
                _createCommentVNode(" Table Start "),
                _createElementVNode("table", _hoisted_21, [
                  _createElementVNode("thead", null, [
                    _createElementVNode("tr", _hoisted_22, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.header, (headerValue, index) => {
                        return (_openBlock(), _createElementBlock("th", { key: index }, _toDisplayString(headerValue.key), 1 /* TEXT */))
                      }), 128 /* KEYED_FRAGMENT */))
                    ])
                  ]),
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item, index) => {
                      return (_openBlock(), _createElementBlock("tr", { key: index }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.header, (headerValue, index) => {
                          return (_openBlock(), _createElementBlock("td", { key: index }, _toDisplayString(item[headerValue.key]), 1 /* TEXT */))
                        }), 128 /* KEYED_FRAGMENT */))
                      ]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ])
                ]),
                _createCommentVNode(" Table End ")
              ])
            ]),
            _createCommentVNode(" End KTDatatable ")
          ]),
          _createCommentVNode(" End Pagination ")
        ])
      ])
    ])
  ], 64 /* STABLE_FRAGMENT */))
}